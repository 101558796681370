const messages = {
    en: {
        'project.title': 'MyDIMOTRANS',

        'ROLE_USER': 'Restricted User',
        'ROLE_DIRECTOR': 'Business Management',
        'ROLE_ADMIN': 'Administrator',
        'ROLE_MULTIUSER': 'Multi User',

        'name/surname': 'Name/Surname',
        disabled_users_list: 'Disabled users',
        company: 'Enterprise',
        profile: 'Profile',
        previous: 'Previous',
        next: 'Next',
        back: 'Back',
        finish: 'Finish',
        submit: 'Submit',
        page_navigation: 'Page navigation',
        remove: 'Remove',
        description: 'Description',
        quantity: 'Quantity',
        length: 'Length',
        width: 'Width',
        height: 'Height',
        weight_gross: 'Gross weight',
        add_goods: 'Add one more item',
        parcels: 'Parcels',
        pallets: 'Pallets',
        origin_country: 'Country of origin',
        destination_country: 'Country of destination',
        city: 'City',
        postal_code: 'Postal code',
        status: 'Status',
        enabled: 'Active',
        internal: 'Internal customer',
        disabled: 'Inactive',
        save_success: 'Successfully saved',
        error_occured: 'An error ocured',
        weight: 'Packing',
        volume: 'Volume',
        total_volume: 'Total volume',
        sender: 'Expeditor',
        recipient: 'Recipient',
        map: 'Map',
        notifications: 'Notifications',
        local_shipping: 'Road',
        local_airport: 'Airline',
        flight: 'Air',
        directions_boat: 'Sea',
        forklift: 'Logistics',
        customs: 'Customs',
        commodity_value: 'Insurance value',
        insurance: 'Insurance',
        list: 'list',
        selected: 'selected',
        select: 'select',
        email: 'Email',
        phone: 'Phone',
        password: 'Password',
        contact_us: 'contact us',
        dashboard: 'Dashboard',
        user: 'User',
        save: 'Save',
        login: 'Login',
        logout: 'Logout',
        document: 'Document',
        select_option: 'Select an option',
        send: 'Send',
        reset_inputs: 'Clear my entry',
        add_email: 'Add email',
        full_article: 'Full Article',
        news: 'News',
        hello: 'Hello',
        download_template: 'Template download',
        upload: 'Upload',
        wrong_format: 'Wrong format',
        connect: 'connect',
        results: 'Results',
        code: 'Code',
        to: 'to',
        of: 'of',
        or: 'or',
        my_quotations: 'my quotations',
        my_tracking: 'my tracking',
        my_dashboard: 'My KPI & Statistics',
        read_more: 'Read more',
        close: 'Close',
        your_file: 'your file',
        import: 'import',
        origin_city: 'Origin city',
        destination_city: 'Destination city',
        contact_form: 'Contact form',
        country: 'Country',
        my_account: 'My account',
        reset: 'Reset',
        privacy_policy: 'Privacy Policy',
        cookies_not_enabled_login: 'In order to login, please accept',
        cookies_not_enabled_register: 'In order to request access to the site, please accept',
        rememberDevice: 'Remember this device',
        'rememberDevice.title': 'Check this option to not re-enter a verification code for 45 days on this device.',
        'rememberDevice.alert': 'Please note, this action will delete other registered devices.',
        email_not_valid: 'This value is not a valid email address.',
        value_too_long: 'This value is too long. It should have {number} characters or less.',
        phone_not_valid: 'Invalid phone format',
        cookies: 'cookies',
        file_name: 'File name',
        no_items_found: 'No items found',
        autocomplete_clear: 'Clear selection',

        mode: 'mode',
        date: 'date',
        reference: 'reference',
        origin: 'origin',
        destination: 'destination',
        kg: 'KG',
        m3: 'M3',
        um: 'U.M.',
        ml: 'LM',
        notify_dossier: 'NOTIFY SOMEONE ABOUT THIS ORDER',
        notify_email: 'Recipient email',
        pod: 'POD',
        quotation: 'Cotation',
        invoice: 'Invoice',
        my_documents: 'My documents',
        dossier_help: 'REQUEST FOR HELP ON THIS FILE',
        dimension: 'dimension',
        postcode: 'Post code',
        post_code: 'Post code',
        help: 'Help',
        community: 'Community',
        delete: 'Delete',
        transfer: 'Transfer',
        delete_confirm: 'Are you sure you want to delete {block}',
        price: 'Price',
        click_here: 'click here',
        danger_goods: 'Dangerous Goods',
        search_reference: 'Search by reference',
        transport: 'Transport',
        documents: 'Documents',
        'cgv.title': 'Terms & Conditions',
        'cgv.accept_text': 'Please accept the',
        'cgv.accept': 'Accept Terms & Conditions',
        'cgv.refuse': 'Refuse',
        'cgv.revoke': 'Revoke acceptance of Terms & Conditions',
        'cgv.signed_at': 'Signed on',
        'cgv.signed_by': 'Signed by',

        'search_placeholder': 'Search on MyDIMOTRANS...',
        'forum.admin': 'Admin',

        'account.creation_request': 'Account creation request',
        'account.enter_fullName': 'Name/Surname',
        'account.enter_email': 'Email',
        'account.enter_phone': 'Phone',
        'account.enter_company': 'Enterprise',
        'account.enter_postcode': 'Post code',
        'account.field_is_required': 'The field "{field}" is required',
        'account.success_text': 'Your request will be taken into account and we will answer you as soon as possible',
        'account.forgot_text': 'Password reset link was sent to the email',
        'account.register': 'Register',
        'account.send': 'Send',

        'account.error_required': 'The field "{field}" is required',
        'account.error_email': 'Invalid email format',

        'global_configuration': 'Configuration',
        'user.management': 'User Management',
        'user.disabled_users': 'Disabled Users',
        'user.srch': 'Search by',
        'user.contact_submit': 'Submit',
        'user.contact_reason': 'Reason',
        'user.contact_details': 'More details',
        'user.password_reset': 'New password',
        'user.password_reset_confirm': 'Are you sure you want to reset the user\'s password?',
        'user.password_reset_success': 'A new user password has been sent via email {email}',
        'user.password_generated_success': 'Un nouveau mot de passe d\'utilisateur a été envoyé par email {email}',
        'user.password_change': 'Modify your password',
        'user.create': 'Create user',
        'user.modify': 'Modify user',
        'user.activate': 'Activate user',
        'user.create_new': 'Create new user',
        'user.info': 'User info',
        'user.data': 'Data sent by the user',
        'user.additional_info': 'Additional information',
        'user.additional_info_desc': 'Please enter additional information that will help us process your request',
        'user.full_name': 'Full name',
        'user.email': 'E-mail',
        'user.enterprise': 'Enterprise',
        'user.enterprise_select': 'Choose Enterprise',
        'user.phone': 'Phone',
        'user.details': 'Details',
        'user.countries_import': 'Import Countries',
        'user.countries_export': 'Export Countries',
        'user.enabled': 'The user has been reactivated',
        'user.not_found': 'User not found',
        'user.email_in_use': 'Email already in use',
        'user.old_password': 'Current password',
        'user.new_password': 'New password',
        'user.new_password_repeat': 'Confirm password',
        'user.old_password_required': 'Current password is a mandatory field',
        'user.password_bad_format': 'Password must contain at least 8 characters, at least one uppercase and one lowercase, and at least one number',
        'user.passwords_dont_match': 'New passwords don\'t match',
        'user.password_changed': 'Password changed successfully',
        'user.request_access': 'Request access',
        'user.forgot_password': 'Forgot password',
        'user.two_factor_auth': 'Two factor authentication',
        'user.tfa_code_enter': 'Please enter the code that was sent to your email',
        'user.commercial': 'Commercial',
        'user.logistics': 'Logistics',
        'user.exact_match': 'Exact search',
        'user.external_resources': 'External resources',
        'user.with_invoices': 'Invoice access',
        'user.allow_tracking_file_upload': 'Tracking file upload',
        'user.can_sign_cgv': 'Can sign Terms & Conditions',
        'user.last_login_date': 'Last login date',
        'user.disabled_at': 'Disabled at',
        'user.last_logout_date': 'Last logout date',
        'user.excel_export': 'Export excel',

        'tracking.no_records': 'No records found',
        'tracking.in_progress': 'In progress',
        'tracking.problem': 'Not delivered',
        'tracking.delivered': 'Delivered',
        'tracking.no_documents': 'No documents',
        'tracking.filter_submit': 'GO',
        'tracking.filter_quick': 'Quick filters',
        'tracking.filter_type': 'Type',
        'tracking.filter_status': 'Status',
        'tracking.filter_reference': 'Search by criteria',
        'tracking.filter_reset': 'Reset filter',
        'tracking.route_received': 'RECEIVED',
        'tracking.route_preparation': 'IN PREPARATION',
        'tracking.route_prepared': 'PREPARED',
        'tracking.route_shipped': 'SHIPPED',
        'tracking.route_cancelled': 'CANCELLED',
        'tracking.estimated_delivery': 'Estimated delivery',
        'tracking.help_tooltip': 'Contact Operator',
        'tracking.help_yes': 'Yes',
        'tracking.help_no': 'No',
        'tracking.help_contact': 'Contact',
        'tracking.help_confirm': 'Do you wish to contact an operator for more details on this tracking record {document} ?',
        'tracking.help_message': 'Hello, can I have more details on the tracking record {document} ? Thank you in advance.',
        'tracking.export_excel': 'Export Excel',
        'tracking.confirm_mail_content': 'Confirmation mail content',
        'tracking.mail_error_email': 'Invalid email address',
        'tracking.mail_error_required': 'The field is required',
        'tracking.hbl': 'HBL',
        'tracking.mbl': 'MBL',
        'tracking.company_name': 'Company name',
        'tracking.flight_number': 'Flight number',
        'tracking.airport_departure': 'Airport of Departure',
        'tracking.airport_arrival': 'Airport of Arrival',
        'tracking.container': 'Container',
        'tracking.container_sealed': 'Sealed',
        'tracking.vessel_name': 'Vessel name',
        'tracking.port_departure': 'Port of departure',
        'tracking.port_arrival': 'Port of arrival',
        'tracking.transport_type': 'Transport mode',
        'tracking.creation_date': 'Creation date',
        'tracking.admin_list': 'Tracking statuses',
        'tracking.status_name': 'Name',
        'tracking.status_list': 'Statuses',
        'tracking.status_edit': 'Edit',
        'tracking.latest_records': 'Latest tracking records',
        'tracking.upload_files': 'Upload files',
        'tracking.choose_file': 'Choose file',
        'tracking.all_enterprises_need_time': 'Searching for all enterprises can take time',
        'tracking.search' : 'Launch research',

        'quotation.request-label': 'A quotation request? Complete the fields:',
        'quotation.address_from': 'Origin',
        'quotation.address_to': 'Destination',
        'quotation.date_from': 'Depart date',
        'quotation.date_to': 'Arrival date',
        'quotation.address_desc': 'Country, city, post code',
        'quotation.save_button': 'Request price',
        'quotation.more_options': 'Advanced',
        'quotation.price_from': 'From',
        'quotation.result_title': 'Result',
        'quotation.back': 'Modify',
        'quotation.step_1': 'Selection',
        'quotation.step_2': 'Credentials',
        'quotation.step_3': 'Personalization',
        'quotation.step_4': 'Confirmation',
        'quotation.login_title': 'Login',
        'quotation.login_description': 'To continue your order, log in!',
        'quotation.error_required': 'Value cannot be empty',
        'quotation.error_postalCode': 'The address is required',
        'quotation.error_float': 'Value must be a number',
        'quotation.error_integer': 'Value must be a number',
        'quotation.error_minValue': 'Value should be at least {value}',
        'quotation.register_title': 'Not a customer yet ?',
        'quotation.register_description': 'Sign up !',
        'quotation.options_title': 'Choose your options',
        'quotation.consult': 'Consult',
        'quotation.next': 'Next',
        'quotation.summary_title': 'Summary of your order',
        'quotation.departure': 'Departure',
        'quotation.arrival': 'Arrival',
        'quotation.history_label_p1': 'my history ',
        'quotation.history_label_p2': 'of quotations',
        'quotation.in_progress_label': 'my quotations in progress',
        'quotation.multiple_label': 'Make multiple quotation',
        'quotation.packages_title': 'Packages',
        'quotation.terms_warning': 'You must accept the terms and conditions to validate your order',
        'quotation.terms_agree': 'I\'ve read and I agree with the general selling conditions',
        'quotation.leave_message': 'Leave us a message',
        'quotation.price_after_login': '* Please choose one of the options to see prices',

        'quotation.dexp_option': 'Customs export',
        'quotation.dimp_option': 'Customs import',
        'quotation.cremb_option': 'With reimbursement',
        'quotation.insurance_option': 'Insurance',
        'quotation.from': 'From',
        'quotation.to': 'To',
        'quotation.depart_date': 'Date of departure',
        'quotation.arrive_date': 'Date of arrival',
        'quotation.package': 'Package',
        'quotation.total': 'Total',
        'quotation.taxable_price': 'Transport Price',
        'quotation.total_price': 'Total price',
        'quotation.tax': 'VAT',
        'quotation.total_price_taxed': 'Total price taxed',
        'quotation.fuel_overload_amount': 'Fuel overload amount',
        'quotation.delivery_assistance': 'Delivery assistance',
        'quotation.option': 'Option',
        'quotation.not_available': 'is unavailable online',
        'quotation.contact_title': 'Please fill in the following information, we will get back to you as soon as possible !',
        'quotation.dangerous_exception': 'Dangerous goods',
        'quotation.option_exception': 'that you have chosen is not available online',
        'quotation.user_exception': 'You are not connected. To continue with the order, please',
        'quotation.user_exception_part.2': 'or make an',
        'quotation.user_exception_part.3': 'If not, please fill in the following information and we will get back to you as soon as possible to process your request.',
        'quotation.title': 'Order summary',
        'quotation.step.1': 'Step 1. Description of your expedition',
        'quotation.step.2': 'Step 2. Check and select',
        'quotation.step.3': 'Step 3. Customize your quotation',
        'quotation.step.4': 'Step 4. Contact information and confirmation',
        'quotation.no_option_selected': 'Please select an option to continue',
        'quotation.multiple': 'Multiple quotations',
        'quotation.get_quotes': 'Get quotations',
        'quotation.no_file_for_upload': 'No file currently selected for upload',
        'quotation.upload_quotations': 'Upload Quotations',
        'quotation.open_chat': 'I will order later',
        'quotation.finish': 'I confirm my order',
        'quotation.contact_operator': 'Contact operator',
        'quotation.quotation_details': 'Quotation details',
        'quotation.quotation_list': 'my history of quotations',
        'quotation.quotation_finalized': 'Quotation History',
        'quotation.request': 'Quotation request',
        'quotation.finalized': 'Finalized',
        'quotation.not_finalized': 'Not Finalized',
        'quotation.expired': 'Expired',
        'quotation.expire_date': 'Expiration date',
        'quotation.select_options': 'Choose your options',
        'quotation.terms_info': 'You have to accept the terms and conditions to validate your order',
        'quotation.terms_agreement': 'I have read and I agree with',
        'quotation.terms': 'the terms and conditions',
        'quotation.terms_title': 'General Terms and Conditions of Sale',
        'quotation.taxed_weight': 'Taxable weight',
        'quotation.request_content': 'Your request is taken into account',
        'quotation.continue': 'Finalize / restart quotation',
        'quotation.delivery_constraints': 'Delivery with specific constraints',
        'quotation.contribution_tax': 'Environment contribution tax',
        'quotation.command_later': 'Are you sure you want to order later?<br />Attention: the quotation request will be valid for {numDays} days.',
        'quotation.command_later_confirm': 'I wish to order later',

        'dashboard.filter_submit': 'Refresh',

        'notifications.enable': 'Activate notifications',
        'notifications.disable': 'Deactivate notifications',
        'notifications.email': 'Email notification',
        'notifications.empty': 'You don\'t have any notifications',

        'datepicker.last_week': 'Last week',
        'datepicker.last_month': 'Last month',
        'datepicker.last_3_months': 'Last 3 months',
        'datepicker.last_6_months': 'Last 6 months',
        'datepicker.last_year': 'Last year',

        'file_upload.drop_upload': 'Drop files here or click to upload',
        'file_upload.toggle': 'Attach a document',
        'file_upload.error.max_files': 'You can only upload {max} files simultaneously',
        'file_upload.error.max_size': 'You can only upload {max} simultaneously',
        'file_upload.error.extension': '{file} has invalid extension',
        'file_upload.error.mime': '{file} has invalid mime type',
        'file_upload.error.transfer': '{file} error while transferring',
        'file_upload.delete_confirm': 'Are you sure you want to delete this attachment?',
        'file_upload.delete_tooltip': 'Deletion',

        'api.api_key': 'API Key',
        'api.generate': 'Generate',
        'api.help_contact': 'contact us',
        'api.help_text_1': 'Our API allow data integration directly into your SI,',
        'api.help_text_2': 'for additional information',

        'contact.quotation': 'Quotation',
        'contact.tracking': 'Tracking',
        'contact.other': 'Other',
        'contact.title': 'I want to be called',
        'contact.saved': 'Request sent',
        'contact.chat': 'Chat with MyDI',
        'contact.tooltip': 'Need assistance ?',
        'contact.message': 'Enter your message...',
        'contact.guest': 'guest',

        'Livraison / Enlèvement hayon': 'Delivery / Pick-up off tailgate',
        'Enlèvement / Livraison porteur': 'Pick-up / Door-to-door delivery',
        'Douane export 100 €': 'Customs - export 100 €',
        'Douane import 140 €': 'Customs - import 140 €',
        'Contre-remboursement +25 €': 'Cash on delivery +25 €',
        'Marchandise dangereuse': 'Dangerous goods',
        'Marchandise à assurer': 'Goods to ensure',
        'ensurance_tooltip_p1': 'Amount of insurance = 0.3% from goods value with a minimum of',
        'ensurance_tooltip_p2': 'To consult the list of ensured goods, please',
        'danger_message': 'of prohibited dangerous goods in our network',
        'l\'économique': 'The economic',
        'Livraison standard': 'Standard delivery',
        'Track&trace': 'Track&Trace',
        'Preuve de livraison en ligne': 'Proof of Delivery Online',
        '72/96h': '72/96h',
        'le plus rapide': 'The fastest',
        'Livraison express': 'Express Delivery',
        'Prix Hors Taxe': 'Price without tax',
        '24h': '24h',
        'le just in time': 'Just in time',
        'Livraison à heure fixe': 'Fixed time delivery',
        'l\'écologique': 'The ecological',
        'Moins de CO2': 'Less CO2',

        'reporting.statistics.download': 'DOWNLOAD ALL STATISTICS',
        'reporting.statistics.turnover': 'My transport budget',
        'reporting.statistics.total_weight': 'Total weight',
        'reporting.statistics.number_dossiers': 'Number of dossiers',
        'reporting.statistics.satisfaction_rate': 'Satisfaction rate',
        'reporting.download': 'Download',
        'reporting.import': 'Import',
        'reporting.export': 'Export',
        'reporting.national': 'National',
        'reporting.other': 'Other',
        'reporting.other_tooltip': 'National and Cross Trade',
        'reporting.graph_satisfied': 'Satisfaction',
        'reporting.graph_unsatisfied': 'Unsatisfied',
        'reporting.total_rows': 'Total entries',
        'reporting.total_income': 'My transport budget total',
        'reporting.create_title': 'Create chart',
        'reporting.edit_title': 'Edit chart',
        'reporting.edit_type': 'Data type',
        'reporting.edit_chart': 'Chart type',
        'reporting.edit_name': 'Chart name',
        'reporting.edit_group': 'Data grouping',
        'reporting.delete_chart': 'Delete chart',
        'reporting.register': 'Register',
        'reporting.add_block_p1': 'Add a',
        'reporting.add_block_p2': 'new block',
        'reporting.delete_confirm': 'this chart?',
        'reporting.money_column': 'My transport budget',
        'reporting.weight_column': 'Weight',
        'reporting.dossiers_column': 'Dossiers',
        'reporting.satisfaction_column': 'Satisfaction',
        'reporting.export_column': 'Export country',
        'reporting.import_column': 'Import country',
        'reporting.traffic_type_column': 'Transport type',
        'reporting.date_months_column': 'Month',
        'reporting.date_days_column': 'Day',
        'reporting.weight': 'Weight',
        'reporting.money': 'Value',
        'reporting.total': 'Total',
        'reporting.satisfaction': 'Satisfaction',
        'reporting.service_tax': 'Service tax',
        'reporting.stock_volume': 'Stock volume',
        'reporting.stock_entry': 'Stock entry',

        'reporting.bar_graph': 'Bar graph',
        'reporting.line_graph': 'Line graph',
        'reporting.pie_graph': 'Pie graph',
        'reporting.gauge_graph': 'Gauge graph',
        'reporting.error_required': 'This field is required',

        'rss.no_news': 'Sorry, no news for the moment',

        'select.default_placeholder': 'Please select...',

        'chat.time_now': 'just now',
        'chat.transfer': 'Transfer the chat',
        'chat.transfer_to': 'Transfer the chat to',
        'chat.transfer_reason': 'Reason for transfer',
        'chat.welcome_gdpr': 'Protection of your privacy is our priority. For more information, please consult our <a href="{url}" target="_blank">Privacy Policy</a>',
        'chat.welcome': 'Hello, my name is MyDI, how can I help you?',
        'chat.me': 'Me',
        'chat.transferred': 'This conversation is transfered',
        'chat.no_sessions': 'You do not have any active chats',
        'chat.busy': 'Busy',
        'chat.wrong_operator': 'Wrong operator',
        'chat.user_request': 'User request',
        'chat.other': 'Other',
        'chat.connecting_to_operator': 'We\'re connecting you to an operator',
        'chat.history': 'chat history',
        'chat.close': 'Close chat',
        'chat.close_conversation': 'Close conversation',
        'chat.close_confirm': 'Are you sure you want to close this chat session?',
        'chat.feedback_1': 'We would like to know your opinion to continue improving our service.',
        'chat.feedback_2': 'How do you rate the quality of the response to your request ?',
        'chat.feedback_sent': 'Thank you, your feedback has been taken into account',
        'chat.feedback_empty': 'Please, complete the satisfaction survey',

        'chat_history.my_conversations': 'My conversations',
        'chat_history.conversations': 'List of conversations',
        'chat_history.send_email': 'Send by email',
        'chat_history.delete_conversation': 'Delete the conversation',
        'chat_history.delete_all_conversations': 'Delete all conversations',
        'chat_history.delete_confirm': 'this conversation?',
        'chat_history.delete_confirm_all': 'all conversations?',
        'chat_history.sent': 'Please check your email, you should soon receive your chat export.',
        'chat_history.search': 'Search by name ...',
        'chat_history.search_user': 'Search by user ...',
        'chat_history.search_operator': 'Search by operator ...',
        'chat.system.nickname': 'MyDI',
        'chat.delay': 'At most <span class="text-bold">{countdown}</span> minutes of waiting',

        'faq.headline': 'Find the answers to the most frequently asked questions!',

        'cookies.accept_text': '#MyDIMOTRANS Platform uses cookies. Our cookies are disabled by default. You can accept these cookies. For more information, please consult our',
        'cookies.accept_password_reset': 'I accept the cookies',
        'cookies.accept': 'I accept',
        'cookies.refuse': 'I refuse',
        'cookies.refuse.title': 'Reject Cookies',
        'cookies.refuse.text': 'You\'re going to be logged out. Are you sure you want to reject the cookies?',

        'wiki.title': 'Find all the useful documents',
        'wiki.add_document': 'Add document',
        'wiki.file_name_required': 'File name is required',
        'wiki.delete_confirm': 'document ?',
        'wiki.add_document_p2': 'document',

        'search.total_results': 'results were found',

        'pom.tooltip': 'My Tools',
        'pom.title': 'My Tools',
        'pom.empty': 'None available',
        'pom.account_create': 'I create an account',
        'pom.account_exists': 'I already have an account',
        'pom.create_toast': 'Your request has been taken into account and we will come back to you within 24 hours',
        'pom.menu': 'My TMS Charger',

        'my_documents.no_records': 'No corresponding document',
        'my_documents.type': 'Document type',
        'my_documents.available_soon': '(available soon)',
        'my_documents.all': 'All',
        'my_documents.latest_documents': 'Latest documents',

        'links.list': 'External access management',
        'links.create': 'Create new link',
        'links.name': 'Name',
        'links.url': 'Url',
        'links.edit': 'Edit',
        'links.edit_title': 'Edit external resource',
        'document_type.delivery_notice': 'Delivery Notice',

        'configuration.num_inactive_days': 'Number of days before deactivation',
        'forced_notification.input_label': 'Always notify email',
        'forced_notification.list': 'Notifications',
        'forced_notification.add': 'Add',
        'forced_notification.delete': 'Delete',
        'forced_notification.save': 'Save',
        'forced_notification.name': 'Name',
        'forced_notification.email': 'Email',
        'forced_notification.type.new': 'New Tracking',
        'forced_notification.type.new_client': 'New Client Tracking',
        'forced_notification.type.update': 'Update Status tracking',
    },
    fr: {
        'project.title': 'MyDIMOTRANS',

        'ROLE_USER': 'Utilisateur Restreint',
        'ROLE_MULTIUSER': 'Multi Utilisateur',
        'ROLE_DIRECTOR': 'Direction Entreprise',
        'ROLE_ADMIN': 'Administrateur',

        'name/surname': 'Nom/Prénom',
        company: 'Entreprise',
        profile: 'Profil',
        previous: 'Précédent',
        next: 'Suivant',
        back: 'Retour',
        finish: 'Soumettre',
        submit: 'Soumettre',
        page_navigation: 'Navigation dans la page',
        remove: 'Effacer',
        description: 'description',
        quantity: 'Quantité',
        length: 'Longueur',
        width: 'Largeur',
        height: 'Hauteur',
        weight_gross: 'Poids brut',
        add_goods: 'Ajouter une marchandise',
        parcels: 'Colis',
        pallets: 'Palettes',
        origin_country: 'Pays d\'origine',
        destination_country: 'Pays de destination',
        city: 'Ville',
        'postal_code': 'Code postal',
        status: 'Statut',
        enabled: 'Actif',
        disabled: 'Inactif',
        internal: 'Client interne',
        save_success: 'Sauvegardé avec succès',
        error_occured: 'Une erreur est survenue',
        weight: 'Colisage',
        volume: 'Volume',
        total_volume: 'Volume total',
        sender: 'Expéditeur',
        recipient: 'Destinataire',
        map: 'Carte',
        notifications: 'Notifications',
        local_shipping: 'Route',
        local_airport: 'Aérien',
        flight: 'Aérien',
        directions_boat: 'Maritime',
        forklift: 'Logistique',
        customs: 'Douane',
        commodity_value: 'Valeur marchandise',
        insurance: 'Assurance',
        list: 'la liste',
        selected: 'sélectionné',
        select: 'sélectionnez',
        email: 'Email',
        phone: 'Numéro de téléphone',
        password: 'Mot de passe',
        contact_us: 'contactez-nous',
        dashboard: 'Tableau de bord',
        user: 'Utilisateur',
        save: 'Sauvegarder',
        login: 'Connexion',
        logout: 'Déconnexion',
        document: 'Document',
        select_option: 'Selectionner une option',
        send: 'Envoyer',
        reset_inputs: 'Effacer ma saisie',
        add_email: 'Ajouter un email',
        full_article: 'Article Complet',
        news: 'Actualités',
        hello: 'Bonjour',
        download_template: 'Télécharger un modèle',
        upload: 'Télécharger',
        wrong_format: 'Mauvais format',
        connect: 'vous connecter',
        results: 'Résultats',
        code: 'Code',
        to: 'à',
        of: 'sur',
        or: 'ou',
        my_quotations: 'mes cotations',
        my_tracking: 'mon tracking',
        my_dashboard: 'Mes KPI & Statistiques',
        read_more: 'Lire la suite',
        close: 'Fermer',
        your_file: 'votre fichier',
        import: 'importer',
        origin_city: 'Ville d\'origine',
        destination_city: 'Ville de destination',
        contact_form: 'Formulaire de contact',
        postcode: 'Code postal',
        post_code: 'Code postal',
        help: 'Aide',
        community: 'Communauté',
        country: 'Pays',
        my_account: 'Mon compte',
        reset: 'Réinitialiser',
        privacy_policy: 'Politique de Protection des Données',
        cookies_not_enabled_login: 'Pour s\'authentifier, merci d\'accepter',
        cookies_not_enabled_register: 'Pour demander accès sur le site, merci d\'accepter',
        rememberDevice: 'Se souvenir de cet appareil',
        'rememberDevice.title': 'Cocher cette option pour ne pas resaisir de code de vérification pendant 45 jours sur cet appareil.',
        'rememberDevice.alert': 'Attention, cette action supprimera les autres appareils enregistrés.',
        email_not_valid: 'Cette valeur n\'est pas une adresse email valide.',
        value_too_long: 'Cette chaîne est trop longue. Elle doit avoir au maximum {number} caractères.',
        phone_not_valid: 'Format de téléphone invalide',
        cookies: 'les cookies',
        file_name: 'Nom de fichier',
        no_items_found: 'Aucun enregistrements trouvé',
        autocomplete_clear: 'Effacer la sélection',

        mode: 'mode',
        date: 'date',
        reference: 'référence',
        origin: 'origine',
        destination: 'destination',
        kg: 'KG',
        m3: 'M3',
        um: 'U.M.',
        ml: 'ML',
        notify_dossier: 'NOTIFIER QUELQU\'UN SUR LE DOSSIER',
        notify_email: 'Email destinataire',
        pod: 'POD',
        quotation: 'Quotation',
        invoice: 'Facture',
        my_documents: 'Mes documents',
        dossier_help: 'DEMANDE D\'AIDE SUR CE DOSSIER',
        dimension: 'dimension',
        delete: 'Supprimer',
        transfer: 'Transférer',
        delete_confirm: 'Voulez-vous vraiment supprimer {block}',
        price: 'Prix',
        click_here: 'cliquer ici',
        danger_goods: 'Marchandises Dangereuses',
        search_reference: 'Recherche par référence',
        transport: 'Transport',
        documents: 'Documents',
        'cgv.title': 'Conditions Générales de Vente',
        'cgv.accept': 'Accepter les CGV',
        'cgv.accept_text': 'Merci d\'accepter les',
        'cgv.refuse': 'Refuser les CGV',
        'cgv.revoke': 'Révoquer la signature des CGV',
        'cgv.signed_at': 'Signé le',
        'cgv.signed_by': 'par',

        'search_placeholder': 'Rechercher sur MyDIMOTRANS...',
        'forum.admin': 'Admin',

        'account.creation_request': 'Demande création compte',
        'account.enter_fullName': 'Nom et Prénom',
        'account.enter_email': 'Email',
        'account.enter_phone': 'Numéro de téléphone',
        'account.enter_company': 'Entreprise',
        'account.enter_postcode': 'Code postal',
        'account.field_is_required': 'Le champ "{field}" est obligatoire',
        'account.success_text': 'Votre demande sera prise en compte et nous vous répondrons dans les meilleurs délais',
        'account.forgot_text': 'Le lien pour reinitialiser votre mot de passe est envoyé',
        'account.register': 'Inscription',
        'account.send': 'Envoyer',

        'account.error_required': 'Le champ "{field}" est obligatoire',
        'account.error_email': 'Format d\'email invalide',

        'global_configuration': 'Configuration',
        'user.management': 'Gestion des utilisateurs',
        'user.disabled_users': 'Utilisateurs désactivés',
        'user.srch': 'Rechercher par',
        'user.contact_submit': 'Soumettre',
        'user.contact_reason': 'Raison',
        'user.contact_details': 'Plus de détails',
        'user.password_reset': 'Nouveau mot de passe',
        'user.password_reset_confirm': 'Êtes-vous sûr de vouloir régénérer le mot de passe de cet utilisateur?',
        'user.password_reset_success': 'Un nouveau mot de passe d’utilisateur a été envoyé par email {email}',
        'user.password_change': 'Modifiez votre mot de passe',
        'user.create': 'Nouvel utilisateur',
        'user.modify': 'Modifier l\'utilisateur',
        'user.activate': 'Activer l\'utilisateur',
        'user.create_new': 'Création d’un Nouvel utilisateur',
        'user.info': 'Infos utilisateur',
        'user.data': 'Données envoyées par l\'utilisateur',
        'user.additional_info': 'Information complémentaire',
        'user.additional_info_desc': 'Merci de saisir l\'information complémentaire qui pourra nous aider traiter votre demande',
        'user.full_name': 'Nom/Prénom',
        'user.email': 'E-mail',
        'user.enterprise': 'Entreprise',
        'user.enterprise_select': 'Choisir l’entreprise',
        'user.phone': 'Téléphone',
        'user.details': 'Détails',
        'user.countries_import': 'Pays Import',
        'user.countries_export': 'Pays Export',
        'user.enabled': 'L\'utilisateur a été réactivé',
        'user.not_found': 'Utilisateur introuvable',
        'user.email_in_use': 'Email déjà utilisé',
        'user.old_password': 'Mot de passe actuel',
        'user.new_password': 'Nouveau mot de passe',
        'user.new_password_repeat': 'Confirmation mot de passe',
        'user.old_password_required': 'Le mot de passe actuel est un champ obligatoire',
        'user.password_bad_format': 'Le mot de passe doit contenir au moins 8 caractères, au moins une majuscule et une minuscule et au moins un chiffre',
        'user.passwords_dont_match': 'Les nouveaux mots de passe ne correspondent pas',
        'user.password_changed': 'Le mot de passe a été changé avec succès',
        'user.request_access': 'Demande d\'accès',
        'user.forgot_password': 'Mot de passe oublié',
        'user.two_factor_auth': 'Authentification à deux facteurs',
        'user.tfa_code_enter': 'Entrer le code qui a été envoyé à votre email',
        'user.commercial': 'Commercial',
        'user.logistics': 'Logistique',
        'user.exact_match': 'Recherche exacte',
        'user.external_resources': 'Accès externe',
        'user.with_invoices': 'Accès aux factures',
        'user.allow_tracking_file_upload': 'Téléchargement des documents dossier',
        'user.can_sign_cgv': 'Autorisé à signer les CGV',
        'user.last_login_date': 'Dernier accès',
        'user.disabled_at': 'Désactive le',
        'user.last_logout_date': 'Dernière déconnexion',
        'user.excel_export': 'Exporter excel',

        'tracking.no_records': 'Aucun enregistrement trouvé',
        'tracking.in_progress': 'En cours',
        'tracking.problem': 'Non Livré',
        'tracking.delivered': 'Livré',
        'tracking.no_documents': 'Aucun document',
        'tracking.filter_submit': 'GO',
        'tracking.filter_quick': 'Filtres rapides',
        'tracking.filter_type': 'Type',
        'tracking.filter_status': 'Statut',
        'tracking.filter_reference': 'Recherche par critères',
        'tracking.filter_reset': 'Réinitialiser le filtre',
        'tracking.route_received': 'REÇUE',
        'tracking.route_preparation': 'EN PRÉPARATION',
        'tracking.route_prepared': 'PRÉPARÉE',
        'tracking.route_shipped': 'EXPÉDIÉE',
        'tracking.route_cancelled': 'ANNULE',
        'tracking.estimated_delivery': 'Livraison estimée',
        'tracking.help_tooltip': 'Contacter opérateur',
        'tracking.help_yes': 'Oui',
        'tracking.help_no': 'Non',
        'tracking.help_contact': 'Contacter',
        'tracking.help_confirm': 'Voulez-vous contacter un opérateur pour demander plus de détails sur le dossier {document} ?',
        'tracking.help_message': 'Bonjour, Est-ce que je pourrais avoir plus de détails sur le dossier {document} ? D’avance merci.',
        'tracking.export_excel': 'Export Excel',
        'tracking.confirm_mail_content': 'Contenu du mail de confirmation',
        'tracking.mail_error_email': 'Adresse email invalide',
        'tracking.mail_error_required': 'Le champ est requis',
        'tracking.hbl': 'HBL',
        'tracking.mbl': 'MBL',
        'tracking.company_name': 'Nom de la compagnie',
        'tracking.flight_number': 'Numéro de vol',
        'tracking.airport_departure': 'Aéroport de départ',
        'tracking.airport_arrival': 'Aéroport d\'arrivée',
        'tracking.container': 'Container',
        'tracking.container_sealed': 'Scellé',
        'tracking.vessel_name': 'Nom du navire',
        'tracking.port_departure': 'Port de départ',
        'tracking.port_arrival': 'Port d\'arrivée',
        'tracking.transport_type': 'Mode transport',
        'tracking.creation_date': 'Date de création',
        'tracking.admin_list': 'Statuts de suivi',
        'tracking.status_name': 'Nom',
        'tracking.status_list': 'Statuts',
        'tracking.status_edit': 'Éditer',
        'tracking.latest_records': 'Dernières commandes',
        'tracking.upload_files': 'Télécharger les documents',
        'tracking.choose_file': 'Choisir un document',
        'tracking.all_enterprises_need_time': 'La recherche pour toutes les entreprises peut prendre du temps',
        'tracking.search' : 'Lancer la recherche',

        'quotation.request-label': 'Une demande de cotation ? Saisissez les éléments :',
        'quotation.address_from': 'Départ',
        'quotation.address_to': 'Arrivée',
        'quotation.date_from': 'Date de départ',
        'quotation.date_to': 'Date d’arrivée',
        'quotation.address_desc': 'Pays, Ville, Code postal',
        'quotation.save_button': 'Obtenir votre prix',
        'quotation.more_options': 'Recherche avancée',
        'quotation.price_from': 'À partir de',
        'quotation.result_title': 'Résultat',
        'quotation.back': 'Modifier',
        'quotation.step_1': 'Séléction',
        'quotation.step_2': 'Coordonnées',
        'quotation.step_3': 'Personnalisation',
        'quotation.step_4': 'Confirmation',
        'quotation.login_title': 'Connectez-vous',
        'quotation.login_description': 'Pour poursuivre votre commande, connectez-vous !',
        'quotation.error_required': 'La valeur ne peut pas être vide',
        'quotation.error_postalCode': 'L’adresse est obligatoire',
        'quotation.error_float': 'La valeur doit être un nombre',
        'quotation.error_integer': 'La valeur doit être un nombre',
        'quotation.error_minValue': 'La valeur doit être au moins {value}',
        'quotation.register_title': 'Pas encore client ?',
        'quotation.register_description': 'Inscrivez-vous !',
        'quotation.options_title': 'Choisissez vos options',
        'quotation.consult': 'Consulter',
        'quotation.next': 'Suivant',
        'quotation.summary_title': 'Récapitulatif de votre commande',
        'quotation.departure': 'Départ',
        'quotation.arrival': 'Arrivée',
        'quotation.history_label_p1': 'mon historique ',
        'quotation.history_label_p2': 'de cotations',
        'quotation.in_progress_label': 'mes Cotations en cours',
        'quotation.multiple_label': 'Faire une cotation multiple',
        'quotation.packages_title': 'Colisage',
        'quotation.terms_warning': 'Vous devez accepter les conditions générales pour valider votre commande',
        'quotation.terms_agree': 'J’ai lu et j\'accepte les conditions générales de vente',
        'quotation.leave_message': 'Laissez-nous un mot',
        'quotation.price_after_login': '* Veuillez choisir une des options pour voir les prix',

        'quotation.dexp_option': 'Douane export',
        'quotation.dimp_option': 'Douane import',
        'quotation.cremb_option': 'Contre-remboursement',
        'quotation.insurance_option': 'Marchandise à assurer',
        'quotation.from': 'De',
        'quotation.to': 'À',
        'quotation.depart_date': 'Date de départ',
        'quotation.arrive_date': 'Date d\'arrivée',
        'quotation.package': 'Colisage',
        'quotation.total': 'Total',
        'quotation.taxable_price': 'Prix transport',
        'quotation.total_price': 'Total HT',
        'quotation.tax': 'TVA',
        'quotation.total_price_taxed': 'Total TTC',
        'quotation.fuel_overload_amount': 'Prix surcharge carburant',
        'quotation.delivery_assistance': 'Livraison / Enlèvement hayon',
        'quotation.option': 'L\'option',
        'quotation.not_available': 'EST INDISPONIBLE en ligne',
        'quotation.contact_title': 'Merci de remplir les informations suivantes, nous allons revenir vers vous au plus vite !',
        'quotation.dangerous_exception': 'Matière dangereuse',
        'quotation.option_exception': 'que vous avez choisie n\'est pas disponible en ligne',
        'quotation.user_exception': 'Vous n\'êtes pas connecté. Pour continuer avec la prise d\'ordre merci de',
        'quotation.user_exception_part.2': 'ou faire une',
        'quotation.user_exception_part.3': 'Si non, merci de remplir les informations suivantes et nous allons revenir vers vous au plus vite afin de traiter votre demande.',
        'quotation.title': 'Récapitulatif de la commande',
        'quotation.step.1': 'Etape 1. Description de votre expédition',
        'quotation.step.2': 'Etape 2. Sélectionner une prestation',
        'quotation.step.3': 'Etape 3. Personnalisez votre cotation',
        'quotation.step.4': 'Etape 4. Coordonnées et confirmation',
        'quotation.no_option_selected': 'Merci d\'avoir choisi une option pour continuer',
        'quotation.multiple': 'Cotation multiple',
        'quotation.get_quotes': 'Obtenir Cotation',
        'quotation.no_file_for_upload': 'Aucun fichier sélectionné pour le téléchargement',
        'quotation.upload_quotations': 'Télécharger les Cotations',
        'quotation.open_chat': 'Commander plus tard',
        'quotation.finish': 'Je valide ma commande',
        'quotation.contact_operator': 'Contacter un Opérateur',
        'quotation.quotation_details': 'Détails du cotation',
        'quotation.quotation_list': 'Mon historique de cotations',
        'quotation.quotation_finalized': 'Historique de cotations',
        'quotation.request': 'Demande cotation',
        'quotation.finalized': 'Finalisé',
        'quotation.not_finalized': 'Non Finalisé',
        'quotation.expired': 'Expiré',
        'quotation.expire_date': 'Date d\'expiration',
        'quotation.select_options': 'Choisissez vos options',
        'quotation.terms_info': 'Vous devez accepter les conditions générales pour valider votre commande',
        'quotation.terms_agreement': 'J\'ai lu et j\'accepte',
        'quotation.terms': 'les conditions générales de vente',
        'quotation.terms_title': 'Conditions Générales de Vente',
        'quotation.taxed_weight': 'Poids taxable',
        'quotation.request_content': 'Votre demande a bien été prise en compte',
        'quotation.continue': 'Finaliser / relancer la cotation',
        'quotation.delivery_constraints': 'Livraison avec contraintes spécifiques',
        'quotation.contribution_tax': 'Contribution sûreté environnement',
        'quotation.command_later': 'Êtes-vous sûr de vouloir commander plus tard?<br />Attention: la demande de cotation sera valable {numDays} jours.',
        'quotation.command_later_confirm': 'Je souhaites commander plus tard',

        'dashboard.filter_submit': 'Rafraichir',

        'notifications.enable': 'Activer les notifications',
        'notifications.disable': 'Désactiver notification',
        'notifications.email': 'Email notification',
        'notifications.empty': 'Vous n\'avez pas de notifications',

        'datepicker.last_week': 'La semaine dernière',
        'datepicker.last_month': 'Le mois dernier',
        'datepicker.last_3_months': '3 derniers mois',
        'datepicker.last_6_months': '6 derniers mois',
        'datepicker.last_year': 'L\'année dernière',

        'file_upload.drop_upload': 'Glisser et déposer des fichiers ici ou cliquer',
        'file_upload.toggle': 'Joindre un document',
        'file_upload.error.max_files': 'Vous ne pouvez pas télécharger que {max} fichiers simultanément',
        'file_upload.error.max_size': 'Vous ne pouvez télécharger que {max} simultanément',
        'file_upload.error.extension': '{file} a une extension non valide',
        'file_upload.error.mime': '{file} a une type mime non valide',
        'file_upload.error.transfer': '{file} erreur pendant le transfert',
        'file_upload.delete_confirm': 'Voulez-vous vraiment supprimer cette pièce jointe?',
        'file_upload.delete_tooltip': 'Suppression',

        'api.api_key': 'Clé API',
        'api.generate': 'Générer',
        'api.help_contact': 'contacter',
        'api.help_text_1': 'Nos API permettent d\'intégrer les données directement dans votre SI, vous pouvez nous',
        'api.help_text_2': 'pour un complément d\'informations',

        'contact.quotation': 'Cotation',
        'contact.tracking': 'Tracking',
        'contact.other': 'Autre',
        'contact.title': 'Être rappelé',
        'contact.saved': 'Demande envoyée',
        'contact.chat': 'Tchater avec MyDI',
        'contact.tooltip': 'Besoin d’assistance ?',
        'contact.message': 'Saisissez votre message...',
        'contact.guest': 'visiteur',

        'Livraison / Enlèvement hayon': 'Livraison / Enlèvement hayon',
        'Enlèvement / Livraison porteur': 'Enlèvement / Livraison porteur',
        'Douane export 100 €': 'Douane export 100 €',
        'Douane import 140 €': 'Douane import 140 €',
        'Contre-remboursement +25 €': 'Contre-remboursement +25 €',
        'Marchandise dangereuse': 'Marchandise dangereuse',
        'Marchandise à assurer': 'Marchandise à assurer',
        'ensurance_tooltip_p1': 'Montant assurance = 0,3% de la valeur marchandise avec un minimum de',
        'ensurance_tooltip_p2': 'Pour consulter la liste de marchandises à assurer, merci de',
        'danger_message': 'des marchandises dangereuses interdites dans notre réseaux',
        'l\'économique': 'L\'économique',
        'Livraison standard': 'Livraison standard',
        'Track&trace': 'Track&trace',
        'Preuve de livraison en ligne': 'Preuve de livraison en ligne',
        '72/96h': '72/96h',
        'le plus rapide': 'Le plus rapide',
        'Livraison express': 'Livraison express',
        'Prix Hors Taxe': 'Prix Hors Taxe',
        '24h': '24h',
        'le just in time': 'Sur-mesure',
        'Livraison à heure fixe': 'Livraison à heure fixe',
        'l\'écologique': 'L\'écologique',
        'Moins de CO2': 'Moins de CO2',

        'reporting.statistics.download': 'TÉLÉCHARGER L’ENSEMBLE DES STATISTIQUES',
        'reporting.statistics.turnover': 'Mon budget transport',
        'reporting.statistics.total_weight': 'Poids total',
        'reporting.statistics.number_dossiers': 'Nombre de dossiers',
        'reporting.statistics.satisfaction_rate': 'Taux de satisfaction',
        'reporting.download': 'Télécharger',
        'reporting.import': 'Import',
        'reporting.export': 'Export',
        'reporting.national': 'National',
        'reporting.other': 'Autre',
        'reporting.other_tooltip': 'National et Cross Trade',
        'reporting.graph_satisfied': 'Satisfaction',
        'reporting.graph_unsatisfied': 'Non satisfait',
        'reporting.total_rows': 'Entrées totales',
        'reporting.total_income': 'Mon budget transport total',
        'reporting.create_title': 'Créer le graphique',
        'reporting.edit_title': 'Modifier le graphique',
        'reporting.edit_type': 'Type de données',
        'reporting.edit_chart': 'Type de Graphique',
        'reporting.edit_name': 'Nom de Graphique',
        'reporting.edit_group': 'Regroupement de données',
        'reporting.delete_chart': 'Supprimer le graphique',
        'reporting.register': 'enregistrer',
        'reporting.add_block_p1': 'Ajouter un',
        'reporting.add_block_p2': 'nouveau bloc',
        'reporting.delete_confirm': 'ce graphique?',
        'reporting.money_column': 'Mon budget transport',
        'reporting.weight_column': 'Poids',
        'reporting.dossiers_column': 'Dossiers',
        'reporting.satisfaction_column': 'Satisfaction',
        'reporting.export_column': 'Pays Export',
        'reporting.import_column': 'Pays Import',
        'reporting.traffic_type_column': 'Type de transport',
        'reporting.date_months_column': 'Mois',
        'reporting.date_days_column': 'Jour',
        'reporting.weight': 'Poids',
        'reporting.money': 'Dépenses',
        'reporting.total': 'Total',
        'reporting.satisfaction': 'Satisfaction',
        'reporting.service_tax': 'Taux de services',
        'reporting.stock_volume': 'Volume en stocks',
        'reporting.stock_entry': 'Entrée en stock',

        'reporting.bar_graph': 'Graphique à barres',
        'reporting.line_graph': 'Graphique linéaire',
        'reporting.pie_graph': 'Camembert',
        'reporting.gauge_graph': 'Graphique de jauge',
        'reporting.error_required': 'La valeur ne peut pas être vide',

        'rss.no_news': 'Désolé, pas de nouvelles actuellement',

        'select.default_placeholder': 'Veuillez choisir ...',

        'chat.time_now': 'juste maintenant',
        'chat.transfer': 'Transférer le chat',
        'chat.transfer_to': 'Transférer le chat vers',
        'chat.transfer_reason': 'Motif du transfert',
        'chat.welcome_gdpr': 'La protection de votre vie privée est notre priorité. Pour plus d\'informations, vous pouvez consulter notre <a href="{url}" target="_blank">Politique de Protection des données</a>',
        'chat.welcome': 'Bonjour, mon nom est MyDI, comment puis-je vous aider?',
        'chat.me': 'Moi',
        'chat.transferred': 'La conversation a été transféré',
        'chat.no_sessions': 'Vous n\'avez pas des chats actives',
        'chat.busy': 'Occupé',
        'chat.wrong_operator': 'Mauvais opérateur',
        'chat.user_request': 'Demande de l\'utilisateur',
        'chat.other': 'Autre',
        'chat.connecting_to_operator': 'Nous vous connectons avec un opérateur',
        'chat.history': 'historique du chat',
        'chat.close': 'fermer le chat',
        'chat.close_conversation': 'Fermer la conversation',
        'chat.close_confirm': 'Etês-vous sûr de vouloir fermer cette session de chat?',
        'chat.feedback_1': 'Nous souhaitons connaître votre avis pour continuer à améliorer la qualité de notre service.',
        'chat.feedback_2': 'Comment évaluez-vous la qualité de la réponse apportée à votre demande ?',
        'chat.feedback_sent': 'Merci, votre feedback à bien été prise en compte',
        'chat.feedback_empty': 'Merci de completer le formulaire de satisfaction',

        'chat_history.my_conversations': 'Mes conversations',
        'chat_history.conversations': 'Liste des conversations',
        'chat_history.send_email': 'Envoyer par mail',
        'chat_history.delete_conversation': 'Supprimer la conversation',
        'chat_history.delete_all_conversations': 'Supprimer toutes les conversations',
        'chat_history.delete_confirm': 'cette conversation?',
        'chat_history.delete_confirm_all': 'toutes les conversations?',
        'chat_history.sent': 'S\'il vous plaît vérifier votre email, vous devriez bientôt recevoir votre exportation de chat.',
        'chat_history.search': 'Rechercher par nom ...',
        'chat_history.search_user': 'Recherche par utilisateur ...',
        'chat_history.search_operator': 'Recherche par opérateur ...',
        'chat.system.nickname': 'MyDI',
        'chat.delay': 'Au plus <span class="text-bold">{countdown}</span> minutes d’attente',

        'faq.headline': 'Trouvez les réponses aux questions le plus fréquemment posées!',

        'cookies.accept_text': 'La Plateforme #MyDIMOTRANS utilise à la fois ses propres cookies et ceux de tiers à des fins techniques, analytiques et marketing. Nos cookies sont désactivés par défaut. Vous pouvez accepter ces cookies. Pour plus d\'informations, veuillez consulter notre',
        'cookies.accept_password_reset': 'J’accept les cookies',
        'cookies.accept': 'J’accepte',
        'cookies.refuse': 'Je refuse',
        'cookies.refuse.title': 'Refuser les Cookies',
        'cookies.refuse.text': 'Vous serez déconnecté. Veuillez vous vraiment refuser les cookies?',

        'wiki.title': 'Retrouvez tous les documents utiles',
        'wiki.add_document': 'Ajouter un document',
        'wiki.file_name_required': 'Le nom du fichier est requis',
        'wiki.delete_confirm': 'document ?',
        'wiki.add_document_p2': 'document',

        'search.total_results': 'réponses pour votre recherche',

        'pom.tooltip': 'Mes Outils',
        'pom.title': 'Mes Outils',
        'pom.empty': 'Aucun disponible',
        'pom.account_create': 'Je crée un compte',
        'pom.account_exists': 'J\'ai déjà un compte',
        'pom.create_toast': 'Votre demande a bien été prise en compte et nous reviendrons vers vous dans un délai de 24h',
        'pom.menu': 'Mon TMS Chargeur',

        'my_documents.no_records': 'Aucun document correspondant',
        'my_documents.type': 'Type de document',
        'my_documents.available_soon': '(disponible prochainement)',
        'my_documents.all': 'Tous',
        'my_documents.latest_documents': 'Documents récents',

        'links.list': 'Gestion accès externe',
        'links.create': 'Ajouter un accès externe',
        'links.name': 'Nom',
        'links.url': 'Url',
        'links.edit': 'Éditer',
        'links.edit_title': 'Accès externe editer',
        'document_type.delivery_notice': 'Bon de Livraison',

        'configuration.num_inactive_days': 'Nombre de jours avant désactivation',
        'forced_notification.input_label': 'Toujours notifier le mail',
        'forced_notification.list': 'Notifications',
        'forced_notification.save': 'Enregistrer',
        'forced_notification.add': 'Ajouter',
        'forced_notification.delete': 'Supprimer',
        'forced_notification.name': 'Nom',
        'forced_notification.type.new': 'Nouveau Tracking',
        'forced_notification.type.new_client': 'Nouveau Tracking Client',
        'forced_notification.type.update': 'Mise à jour Statut tracking',
    }
}

export default messages;
