export default {
    type: 3,
    date: null,
    arriveDate: null,
    weight: null,
    volume: null,
    mapButtonClass: {opacity50: true},
    coordinates: {
        origin: null,
        destination: null,
    },
    rows: [
        {
            quantity: 1,
            description: null,
            length: null,
            width: null,
            height: null,
            weight: 2,
            volume: null
        }
    ],
    places: {
        origin: {
            country: null,
            city: null,
            postalCode: null,
            formattedAddress: null,
            countryFull: null,
        },
        destination: {
            country: null,
            city: null,
            postalCode: null,
            formattedAddress: null,
            countryFull: null,
        },
    },
    userData: {
        name: null,
        email: null,
        enterprise: null,
        phone: null,
        details: null,
    },
    additionalOptions: [],
    insuranceValue: null,
    advanced: false,

    loading: false,
    selectedDeliveryOption: null,
    selectedOptionType: null,
    deliveryTypes: {
        STANDARD: 'orange',
        PREMIUM: 'blue',
        DATE: 'red',
        ECO: 'green',
    },
    responseDeliveryOptions: {
        STANDARD: null,
        PREMIUM: null,
        DATE: null,
        ECO: null,
    },
    termsAgreed: false,
    baseSubmitted: false,
    exception: null,
}
