// assets/js/app.js
import Vue from 'vue'
import VueFormWizard from 'vue-form-wizard'
import VueI18n from 'vue-i18n'
import Toasted from 'vue-toasted'
import VTooltip from 'v-tooltip'
import VueMoment from 'vue-moment'
import VueCarousel from 'vue-carousel'
import wysiwyg from 'vue-wysiwyg';

const locale = document.locale ? document.locale : 'fr';
const moment = require('moment');
require('moment/locale/fr');
if (locale === 'en') {
    require('moment/locale/en-gb')
}

import messages from './Messages'

Vue.use(VueFormWizard);
Vue.use(VueI18n);
Vue.use(VTooltip);
Vue.use(VueCarousel);
Vue.use(Toasted, {
    iconPack: 'material',
    duration: 2000,
    position: 'bottom-left',
    theme: 'bubble',
});

Vue.use(VueMoment, {moment});
Vue.use(wysiwyg, {});

const i18n = new VueI18n({
    locale: locale, // set locale
    messages // set locale messages
});

Vue.prototype.$bus = new Vue({});

// Global vue states
import quotationData from './components/quotation/SharedData';
const sharedData = {
    quotation: quotationData,
};

// const NODE_ENV = process.env.NODE_ENV

/**
* Create a fresh Vue Application instance
*/
new Vue({
    i18n,
    el: '#app-main',
    data: sharedData,
    components: {
        AccountCreate: () => import('./components/AccountCreate'),
        ForgotPassword: () => import('./components/ForgotPassword'),
        HeaderBlock: () => import('./components/HeaderBlock'),
        OperatorHeaderBlock: () => import('./components/OperatorHeaderBlock'),
        Home: () => import('./components/Home'),
        QuotationWizard: () => import('./components/quotation/QuotationWizard'),
        CotationMultiple: () => import('./components/CotationMultiple'),
        QuotationList: () => import('./components/quotation/QuotationList'),
        Tracking: () => import('./components/Tracking'),
        TrackingByToken: () => import('./components/TrackingByToken'),
        ForcedNotificationMailInput: () => import('./components/ForcedNotificationMailInput'),
        ForcedNotificationsList: () => import('./components/ForcedNotificationsList'),
        ForumAdmin: () => import('./components/forum/Admin'),
        GlobalConfiguration: () => import('./components/GlobalConfiguration'),
        DisabledUserList: () => import('./components/DisabledUserList'),
        UserList: () => import('./components/UserList'),
        UserCreateEdit: () => import('./components/UserCreateEdit'),
        Notifications: () => import('./components/Notifications'),
        Preloader: () => import('./components/Preloader'),
        Modal: () => import('./components/Modal'),
        Contact: () => import('./components/Contact'),
        FileUpload: () => import('./components/FileUpload'),
        Api: () => import('./components/Api'),
        QuotationBase: () => import('./components/quotation/QuotationBase'),
        Toggle: () => import('./components/Toggle'),
        Reporting: () => import('./components/reporting/Reporting'),
        QuotationUser: () => import('./components/quotation/QuotationUser'),
        Wiki: () => import('./components/Wiki'),
        Faq: () => import('./components/Faq'),
        DjSelect: () => import('./components/Select'),
        DjRating: () => import('./components/Rating'),
        DjChat: () => import('./components/Chat'),
        DjOperatorChat: () => import('./components/OperatorChat'),
        ChatHistory: () => import('./components/ChatHistory'),
        OperatorChatHistory: () => import('./components/OperatorChatHistory'),
        AdminChatHistory: () => import('./components/AdminChatHistory'),
        UserProfile: () => import('./components/UserProfile'),
        WebPush: () => import('./components/WebPush'),
        Cookies: () => import('./components/Cookies'),
        CookiesAccept: () => import('./components/CookiesAccept'),
        TermsFr: () => import('./components/TermsFR'),
        TermsEn: () => import('./components/TermsEN'),
        TermsSign: () => import('./components/TermsSign'),
        TermsPopup: () => import('./components/TermsPopup'),
        MyDocumentsList: () => import('./components/MyDocumentsList'),
        GlobalSearch: () => import('./components/GlobalSearch'),
        Pom: () => import('./components/POM'),
        SetNewPassword: () => import('./components/SetNewPassword'),
        ExternalLinks: () => import('./components/ExternalLinks'),
        ExternalLinksEdit: () => import('./components/ExternalLinksEdit'),
        EnterpriseSelect: () => import('./components/EnterpriseSelect'),
        TrackingStatuses: () => import('./components/TrackingStatuses'),
        TrackingStatusesEdit: () => import('./components/TrackingStatusesEdit'),
    }
});
